<template>
  <div>
    <Header></Header>
    <BGImg></BGImg>
    <div style="display: flex;margin: 150px auto;max-width: 800px;">
      <TinyMCE style="width: 400px;" :edit_bar_show="false" :value="aboutHtml"></TinyMCE>
      <div style="width: 400px;height: 300px;margin-left: 100px;">
        <div id="map"></div>
        <div style="margin-top: 20px;">
          <el-button type="primary" @click="gotoMap()">
            <i class="el-icon-s-promotion"></i>
            开始导航
          </el-button>
        </div>
       </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header";
import BGImg from "@/components/BGImg";
import Footer from "@/components/Footer";
import AMap from "AMap";
import TinyMCE from "@/components/TinyMCE";
let map = null

export default {
  components: {TinyMCE, Footer, BGImg, Header},
  methods:{
    gotoMap(){
      window.open('https://uri.amap.com/marker?position=121.438131,31.291882&name=%E4%B8%AD%E5%9B%BD%E5%AE%89%E8%83%BD%E9%9B%86%E5%9B%A2%E5%8D%8E%E4%B8%9C%E6%8A%95%E8%B5%84%E5%BC%80%E5%8F%91%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8')
    }
  },
  data(){
    return{
      aboutHtml:'',
    }
  },
  mounted() {
    this.axios.get('/api/document/user/article/view/'+160+'/').then(res=>{
      console.log(res.data)
      this.aboutHtml = res.data.body_content
    })

    map =	new AMap.Map("map", {
      center: [121.438131, 31.291882],
      zoom: 16
    })
    // 创建一个 Marker 实例：
    var marker = new AMap.Marker({
      position: new AMap.LngLat(121.438131, 31.291882),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: '上海市静安区汶水路299弄17、18号'
    });

// 将创建的点标记添加到已有的地图实例：
    map.add(marker);
  }
}
</script>
<style scoped>
#map {
  width: 100%;
  height: 100%;
}
</style>